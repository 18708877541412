import React from 'react'

interface Props {
  width?: string
  height?: string
  fill?: string
}

const IconRuble: React.FC<Props> = ({ width = '24', height = '24', fill = 'currentColor' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.5 3H8V21H10.5V16.5H13.5C16.8137 16.5 19.5 13.8137 19.5 10.5C19.5 7.18629 16.8137 4.5 13.5 4.5H10.5V3H13.5ZM10.5 6.75H13.5C15.5711 6.75 17.25 8.42893 17.25 10.5C17.25 12.5711 15.5711 14.25 13.5 14.25H10.5V6.75Z'
        fill={fill}
      />
      <path d='M7 12H15' stroke={fill} strokeWidth='2' strokeLinecap='round' />
    </svg>
  )
}

export default IconRuble
